import React, { useEffect } from 'react';

const AdSenseBanner = () => {
    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return (
        <>
            
            <ins className="adsbygoogle"
                style={{ display: 'block', height: "90px" }}
                data-ad-client="ca-pub-9030836487665695"
                data-ad-slot="5449263688"
                // data-full-width-responsive="true"
                >
                    
                </ins>
        </>
    );
};

export default AdSenseBanner;
