import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import hhImage from '../assets/ic_launcher-playstore.png'
import Image from 'react-bootstrap/Image';
import axios from "axios";
import { HouseFill, BarChartFill, Backpack2Fill, PinMapFill, TrophyFill, CardHeading, QuestionOctagonFill, PersonFillGear } from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

const AppNavbar = (props) => {
  // console.log('props in navbar', props)

  // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
  const mainPageRoutes = ['/'];
  // Check if the current route is a main page route
  const location = useLocation();
  const isMainPage = mainPageRoutes.includes(location.pathname);

  const HhNavbar = () => {
    return (<Navbar bg="dark" variant="dark" expand="md" collapseOnSelect fixed="top">
      <Container>
        <Link to={'/home'}>
          <Image src={hhImage} height='40px' rounded />
          <span className="text-white ms-2" style={{ textDecoration: 'none' }}>Home</span>
        </Link>
        

        {props.isLoggedIn && <Navbar.Toggle aria-controls="basic-navbar-nav" />}
        {props.isLoggedIn && <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/home" eventKey="1"><HouseFill className='me-2' />Home</Nav.Link>
            <Nav.Link as={Link} to="/rankingspre" eventKey="2"><BarChartFill className='me-2' />Rankings</Nav.Link>
            <Nav.Link as={Link} to="/challenges" eventKey="3"><Backpack2Fill className='me-2' />Challenges</Nav.Link>
            <Nav.Link as={Link} to="/downloadedhills" eventKey="4"><PinMapFill className='me-2' />Download Hills</Nav.Link>
            <Nav.Link as={Link} to="/trophies" eventKey="5"><TrophyFill className='me-2' />Trophies</Nav.Link>
            <Nav.Link as={Link} to="/info" eventKey="6"><CardHeading className='me-2' />Info & Rules</Nav.Link>
            <Nav.Link as={Link} to="/tutorial" eventKey="7"><QuestionOctagonFill className='me-2' />Tutorial</Nav.Link>
            <Nav.Link as={Link} to="/account" eventKey="8"><PersonFillGear className='me-2' />Account Settings</Nav.Link>
          </Nav>
        </Navbar.Collapse>}
      </Container>
    </Navbar>)
  }


  const MainPageNavbar = () => {

    const [navBackground, setNavBackground] = useState(false);

    // change class when scrolled. Make white background
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavBackground(true);
      } else {
        setNavBackground(false);
      }
    };

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return (
      <Navbar expand="sm" fixed="top" id="mainNav" className={navBackground && 'navbar-shrink'}>
        <Container>
          <Image className='me-2' src={hhImage} height='40px' rounded />
          <Navbar.Brand href="#page-top">Hill Hunter GPS</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarResponsive" />
          <Navbar.Collapse id="navbarResponsive">
            <Nav className="ms-auto me-4">
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link href="#getapp">Get App</Nav.Link>
              <Nav.Link href="#faq">FAQ</Nav.Link>
            </Nav>
            <Link to={'/register'}>
              <Button variant="primary">Register</Button>
            </Link>
          </Navbar.Collapse>

        </Container>
      </Navbar>
    )
  }





  return (
    <>
      {isMainPage ? <MainPageNavbar /> : <HhNavbar />}
    </>
  );
};

export default AppNavbar;