import FloatingMapButton from '../FloatingMapButton';
import { Link, useNavigate } from 'react-router-dom';
import { countryCode2name, countryCode2flag, challengeCode2Name, periodCode2Name } from "../constants";
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect } from "react";
import { db } from "../db";
import axios from 'axios';
import { sendLocalCapturedHills, pullAndInsertCapturedHills, pullAndInsertBountyHills } from '../helpers'
import { Button, Modal } from "react-bootstrap"
import IosButton from '../../assets/img/ios-button-icon.png'
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import {axiosWcache} from '../../interceptors/axios'

const Home = () => {

    const [isSyncing, setIsSyncing] = useState(false);
    const [standings, setStandings] = useState(null);
    const [topStandings, setTopStandings] = useState(null);
    const [isIos, setIsIos] = useState(typeof DeviceOrientationEvent.requestPermission === 'function');
    const [syncTime, setSyncTime] = useState(localStorage.getItem('last_sync'));
    const [userRegionCode, setUserRegionCode] = useState(localStorage.getItem('user_region'));
    const [showAppInstallModal, setShowAppInstallModal] = useState(!window.matchMedia('(display-mode: standalone)').matches)
    const navigate = useNavigate();
    // console.log('installed???',window.matchMedia('(display-mode: standalone)').matches)


    useEffect(() => {
        // redirect to login page if not logged
        if (localStorage.getItem('access_token') === null) {
            console.log('no access token. redirecting from home page...');
            window.location.href = '/login'
            return
        }

        // run tutorial if this is first time logged
        if (!JSON.parse(localStorage.getItem('wasTutorial'))) {
            localStorage.setItem('wasTutorial', true);
            navigate('/tutorial')
            return
        }


        // access geolocation to request permission for app ASAP when user loggs in first time. hopefully before it is installed on iOS as PWA.
        // iOS has issues with permission window when installed as PWA.
        // run this only first time
        if (!JSON.parse(localStorage.getItem('wasLogged'))) {
            const successCallback = (geolocation) => {
                // console.log('geolocation', geolocation);
            };

            const errorCallback = (error) => {
                // console.log('geolocation error', error);
            };

            const geolocationOptions = {
                enableHighAccuracy: true,
                maximumAge: 10000,
                timeout: 5000,
            };

            if ("geolocation" in navigator) {
                // Access the API
                navigator.geolocation.getCurrentPosition(
                    successCallback,
                    errorCallback,
                    geolocationOptions
                );
                localStorage.setItem('wasLogged', true);
                // console.log('teraz je po gps requeste')
            } else {
                // Use a third-party geolocation service
                console.log("Browser does not support the Geolocation API");
            }
        }

        // get standings from the server
        axiosWcache.get(process.env.REACT_APP_BACKEND_URL + `/v2/api/standing/?u=${localStorage.getItem('username')}`)
            .then(response => {
                // console.log('response', response)
                // Handle success
                setStandings(response.data);
            })
            .catch(error => {
                // Handle error
                console.error(error);
            });


        /////// check if autosync is needed based on timing or captured hill
        // get current timestamp in local timezone
        const last_timestamp = new Date(syncTime);
        // Calculate the difference in milliseconds
        const currentTime = new Date();
        const timeDifference = currentTime - last_timestamp;
        // Convert milliseconds to seconds
        const secondsDifference = timeDifference / 1000;
        // sync if last sync is more than one hour
        if (secondsDifference > 3600) {
            handleSync()
        } else {
            const capHillsLocallyPrem = db.captures.toArray();
            capHillsLocallyPrem.then((val) => {
                // console.log(val);
                if (val.length > 0) {
                    handleSync()
                }
            }).catch((err) => {
                console.log(err);
            });
        }

    }, []);


 
    useEffect(() => {
        if (standings) {
            const lowestRankCurrElements = getLowestRankCurrElements(standings);
            setTopStandings(lowestRankCurrElements)
        }
    }, [standings]);


    const handleSync = async () => {
        setIsSyncing(true);
        // update captured hills
        await sendLocalCapturedHills()
        await pullAndInsertCapturedHills()
        await pullAndInsertBountyHills(JSON.parse(localStorage.getItem('downloaded_regions')))
        await pullAndSaveUserDetails()
        setIsSyncing(false);
    };

    const pullAndSaveUserDetails = async () => {
        await axios.get(process.env.REACT_APP_BACKEND_URL + `/v2/api/usersettings/?u=${localStorage.getItem('username')}`)
            .then(response => {
                // Handle success
                const user_region = response.data.userdata[0].region
                setUserRegionCode(user_region)
                localStorage.setItem('user_region', user_region);
                updateSyncTimestamp()
            })
            .catch(error => {
                // Handle error
                console.error(error);
            });
    };


    const updateSyncTimestamp = () => {
        // get current timestamp in local timezone
        const timestamp = Date.now();
        const dateObj = new Date(timestamp);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        const hours = String(dateObj.getHours()).padStart(2, '0');
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const seconds = String(dateObj.getSeconds()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        setSyncTime(formattedDate)
        localStorage.setItem('last_sync', formattedDate);
    };


    const HomeHeadingCard = () => {
        return <div className="card card-body m-2 text-center">
            <div className='d-flex justify-content-center align-items-center'>
                <div className='fs-2 text-capitalize'>{localStorage.getItem('username')}</div>
                <div className='ms-1'>
                    {userRegionCode && <img alt='' src={require(`../../assets/flags/${countryCode2flag[userRegionCode]}.png`)} height='30px' />}
                </div>
            </div>
            <div >Synced: {syncTime}</div>

            <div className='row'>
                <div className='col-6'>
                    {isSyncing ?
                        <Button variant="primary" disabled className='custom-rounded-button btn-sm'>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Syncing...
                        </Button>
                        :
                        <Button variant="primary" onClick={handleSync} className="custom-rounded-button btn-sm btn btn-primary">
                            Sync Data
                        </Button>
                    }

                </div>
                <div className='col-6'>
                    <Link to={'/profile'} state={{ 'username': localStorage.getItem('username') }} className="btn-sm btn btn-primary custom-rounded-button">
                        Show Profile
                    </Link>
                </div>
            </div>
        </div>
    }

    const HomeOverviewCard = () => {
        const standing_life = standings.standings.filter(
            (entry) =>
                entry.region === 'world' &&
                entry.challenge === 'count' &&
                entry.period_type === 'life'
        )[0];
        const standing_year = standings.standings.filter(
            (entry) =>
                entry.region === 'world' &&
                entry.challenge === 'count' &&
                entry.period_type === 'year'
        )[0];
        const standing_quarter = standings.standings.filter(
            (entry) =>
                entry.region === 'world' &&
                entry.challenge === 'count' &&
                entry.period_type === 'quarter'
        )[0];

        return <div className="card card-body m-2 text-center">

            <div className='row'>
                <div className='col-4'>
                    Lifetime
                </div>
                <div className='col-4'>
                    Year
                </div>
                <div className='col-4'>
                    Quarter
                </div>
            </div>

            <div className='row'>
                <div className='col-4'>
                    {standing_life?.score}
                </div>
                <div className='col-4'>
                    {standing_year?.score}
                </div>
                <div className='col-4'>
                    {standing_quarter?.score}
                </div>
            </div>
        </div>
    }

    const TopRanksCard = ({ standing }) => {
        return (<div className="card card-body m-2">
            <div className='row'>
                <div className='col-6'>
                    <div >Challenge: {challengeCode2Name[standing.challenge]}</div>
                    <div >Region: {countryCode2name[standing.region]}</div>
                    <div >Period: {periodCode2Name[standing.period_type]}</div>
                </div>
                <div className='col-6 d-flex justify-content-end align-items-center'>
                    <img alt='' src={require(`../../assets/flags/${countryCode2flag[standing.region]}.png`)} />
                </div>
            </div>

            <hr className="border-2 border-top my-1 bg-secondary" />
            <div className='row text-center'>
                <div className='col-6'>
                    Current Rank
                </div>
                <div className='col-6'>
                    Highest Rank
                </div>
            </div>

            <div className='row text-center'>
                <div className='col-6'>
                    {standing.rank_curr}
                </div>
                <div className='col-6'>
                    {standing.rank_best}
                </div>
            </div>

        </div>)
    }

    function getLowestRankCurrElements(data) {
        // Sort the standings array first by rank_curr in ascending order,
        // and then by region in descending order
        const sortedStandings = data.standings.sort((a, b) => {
            if (a.rank_curr !== b.rank_curr) {
                return a.rank_curr - b.rank_curr;
            } else {
                // If rank_curr is the same, sort by region in descending order
                return b.region.localeCompare(a.region);
            }
        });
        // Take the first three elements (lowest rank_curr)
        const lowestRankCurrElements = sortedStandings.slice(0, 3);
        return lowestRankCurrElements;
    }


    const handleShowDialogAppInstall = () => {
        setShowAppInstallModal(!showAppInstallModal);
    };


    if (!localStorage.getItem('access_token')) {
        return <div className="text-center"><Spinner animation="border" variant="primary" /></div>
    }


    return (
        <>
            <div className='navbarmargin'>
                <HomeHeadingCard />
                <h3 className='text-center text-white bg-primary py-1'>Captured Hills Overview</h3>
                {standings ? <HomeOverviewCard /> : <div className="text-center"><Spinner animation="border" variant="primary" /></div>}
                <h3 className='text-center text-white bg-primary py-1'>Top 3 Current Rankings</h3>

                {topStandings && topStandings.map((entry) => (
                    <TopRanksCard key={entry.id} standing={entry} />
                ))}

                <FloatingMapButton />

                {/* // modal with info about app installation */}
                <div>
                    <Modal show={showAppInstallModal} onHide={handleShowDialogAppInstall} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Install this app</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            { isIos ? <div>Click button with this icon <img alt='' src={IosButton} className="img-fluid"></img> below, and select "Add to Home Screen"</div>
                            : <div>Click browser options (e.g. icon like this <ThreeDotsVertical/> in chrome browser top right), and select "Add to Home Screen" and "Install"</div>}
                        </Modal.Body>
                    </Modal>
                </div>

            </div>
        </>
    );
};

export default Home;