import { useState, useEffect } from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import { countryCode2name, countryCode2flag, trophyId2ImageName, challengeCode2Name, periodCode2Name } from "../constants";
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import FloatingMapButton from '../FloatingMapButton';

function Trophies(props) {

    const [dataTrophies, setDataTrophies] = useState(null);
    const username = props.username || localStorage.getItem('username');

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + `/v2/api/trophies/?u=${username}`)
            .then(response => {
                // Handle success
                setDataTrophies(response.data);
            })
            .catch(error => {
                // Handle error
                console.error(error);
            });
    }, []);


    if (!dataTrophies) {
        return <div className="text-center mt-4 navbarmargin"><Spinner animation="border" variant="primary" /></div>
    }

    return (
        <>
            {props.IsMainTab && <div className='navbarmargin'></div>}

            <div className="row d-flex align-items-center mx-1 text-center fw-bold">
                <div className="col-3">
                    <div className="row">
                        <div className="col">Trophy</div>
                    </div>
                    <div className="row">
                        <div className="col fw-normal">and</div>
                    </div>
                    <div className="row">
                        <div className="col">Period</div>
                    </div>
                </div>

                <div className="col-3">
                    <div className="row">
                        <div className="col">Challenge</div>
                    </div>
                    <div className="row">
                        <div className="col fw-normal">and</div>
                    </div>
                    <div className="row">
                        <div className="col">Period Type</div>
                    </div>
                </div>

                <div className="col-3">
                    Region
                </div>

                <div className="col-3">
                    <div className="row">
                        <div className="col">Position</div>
                    </div>
                    <div className="row">
                        <div className="col fw-normal">and</div>
                    </div>
                    <div className="row">
                        <div className="col">Score</div>
                    </div>
                </div>

            </div>


            <ListGroup>
                {dataTrophies?.trophies.map((trophy, index) => (
                    <ListGroup.Item key={trophy.id} >
                        <div className="row d-flex align-items-center text-center">
                            <div className="col-3">
                                <div className="row">
                                    <div className="col"><img alt='' height={55} src={require(`../../assets/trophies/${trophyId2ImageName[trophy.trophy_id]}.png`)} /></div>
                                </div>
                                <div className="row">
                                    <div className="col">{trophy.period_win}</div>
                                </div>

                            </div>
                            <div className="col-3">
                                <div className="row">
                                    <div className="col mb-2">{challengeCode2Name[trophy.challenge]}</div>
                                </div>
                                <div className="row">
                                    <div className="col">{periodCode2Name[trophy.period_type]}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="row">
                                    <div className="col"><img alt='' src={require(`../../assets/flags/${countryCode2flag[trophy.region]}.png`)} /></div>
                                </div>
                                <div className="row">
                                    <div className="col">{countryCode2name[trophy.region]}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="row">
                                    <div className="col mb-2">{trophy.position}</div>
                                </div>
                                <div className="row">
                                    <div className="col">{trophy.score.toFixed(2)}</div>
                                </div>
                            </div>
                        </div>

                    </ListGroup.Item>
                ))}
            </ListGroup>

            {props.IsMainTab && <FloatingMapButton />}
        </>
    );


}

export default Trophies;